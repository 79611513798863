//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  defineComponent,
  computed,
  ref,
  useContext,
  useRouter,
  useRoute,
} from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'RkBanner',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    desktopImage: {
      type: String,
      default: '',
    },
    desktopImageRetina: {
      type: String,
      default: '',
    },
    tabletImage: {
      type: String,
      default: '',
    },
    tabletImageRetina: {
      type: String,
      default: '',
    },
    mobileImage: {
      type: String,
      default: '',
    },
    mobileImageRetina: {
      type: String,
      default: '',
    },
    alt: {
      type: [String, null],
      default: '',
    },
    width: {
      type: [String, Number],
      default: '',
    },
    height: {
      type: [String, Number],
      default: '',
    },
    mobileWidth: {
      type: [String, Number],
      default: '',
    },
    mobileHeight: {
      type: [String, Number],
      default: '',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    logo: {
      type: String,
      default: '',
    },
    logoAlt: {
      type: String | null,
      default: '',
    },
    logoWidth: {
      type: [String, Number],
      default: '',
    },
    logoHeight: {
      type: [String, Number],
      default: '',
    },
    loading: {
      type: String,
      default: 'lazy',
      validator: (value) => ['', 'lazy', 'eager'].includes(value),
    },
    hasCta: {
      type: Boolean,
      default: false,
    },
    alignment: {
      type: String,
      required: false,
      validator(value) {
        const align = ['left', 'center', 'right', ''];
        return align.includes(value);
      },
    },
    textColor: {
      type: String,
      default: 'white',
    },
    videoUid: {
      type: String,
      default: '',
    },
    videoUidMobile: {
      type: String,
      default: '',
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    posterTime: {
      type: String | Number,
      default: 0,
    },
    posterHeight: {
      type: String | Number,
      default: 0,
    },
    posterHeightMobile: {
      type: String | Number,
      default: 0,
    },
    aspectRatio: {
      type: String,
      default: '56.25%',
    },
    aspectRatioMobile: {
      type: String,
      default: '56.25%',
    },
    showTitleOnMobile: {
      type: Boolean,
      default: true,
    },
    showSubTitleOnMobile: {
      type: Boolean,
      default: false,
    },
    showCtaOnMobile: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const context = useContext();
    const cloudflareCustomerCode = ref(context.$config.cloudflareCustomerCode);

    const wrapper = computed(() => {
      return props.link ? 'RkLink' : 'div';
    });

    const route = useRoute();
    const router = useRouter();

    const textColorClass = (textColor) => {
      return `text-${textColor}`;
    };

    const hasCtaClass = () => {
      return 'rk-banner__content--has-cta';
    };

    const handleLink = () => {
      if (props.link && route.value.path !== props.link) router.push({ path: props.link });
    };

    return {
      wrapper,
      hasCtaClass,
      textColorClass,
      handleLink,
      cloudflareCustomerCode,
    };
  },
});
