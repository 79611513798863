import { useContext } from '@nuxtjs/composition-api';

export function getStoryLinkPath(path: string, linktype: string) {
  const { localePath } = useContext();

  if (linktype === 'url') {
    return path;
  }
  return localePath('/' + path);
}
