import { render, staticRenderFns } from "./RkBanner.vue?vue&type=template&id=42bd0b11"
import script from "./RkBanner.vue?vue&type=script&lang=js"
export * from "./RkBanner.vue?vue&type=script&lang=js"
import style0 from "./RkBanner.vue?vue&type=style&index=0&id=42bd0b11&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RkPicture: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkPicture/RkPicture.vue').default,RkHeading: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkHeading/RkHeading.vue').default,RkButton: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkButton/RkButton.vue').default,RkImage: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkImage/RkImage.vue').default,RkSiteWrapper: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkSiteWrapper/RkSiteWrapper.vue').default})
