//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed } from '@nuxtjs/composition-api';
import { getStoryLinkPath } from '~/modules/storyblok/helpers/getStoryLinkPath';
import LazyHydrate from 'vue-lazy-hydration';

export default defineComponent({
  props: ['blok'],
  components: {
    LazyHydrate,
  },
  setup(props) {
    const wrapper = computed(() => {
      return (props.fullWidth = true ? 'div' : 'RkSiteWrapper');
    });

    return { wrapper, getStoryLinkPath };
  },
});
