import { render, staticRenderFns } from "./BannersGrid.vue?vue&type=template&id=1afb3349"
import script from "./BannersGrid.vue?vue&type=script&lang=js"
export * from "./BannersGrid.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Banner: require('/var/www_vsf/htdocs/components/storyblok/Banner.vue').default,RkSiteWrapper: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkSiteWrapper/RkSiteWrapper.vue').default})
