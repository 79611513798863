//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';

export default defineComponent({
  props: ['blok'],
  components: {
    LazyHydrate,
    Banner: () => import(/* webpackPrefetch: true */ './Banner.vue'),
  },
});
